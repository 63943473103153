import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IBreakingFields, IBreakingDetial, IBreakingSearch, IBreakingDetailFields, IBreakingDetailSearch, IBreakingDetailDetial } from "./breakingType"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { breakingService } from "./breakingService";
import { ISearchPage } from "@/types/AppTypes";
import useGlobalModel, { transPgToPagination } from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";

export const useBreakingModel = createModel(function () {
    const {restaurantSourceOne} = useGlobalModel()

    //列表数据源
    const [dataSource, setDataSource] = useState<IBreakingDetial[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IBreakingFields>({});
    //抽屉框
    const [visibleModel, setVisibleModel] = useState<boolean>(false);

    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);


    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const loadData = async (page?: ISearchPage, searchValue: IBreakingFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await breakingService.page(searchParams);
        let { data, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(data)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: IBreakingFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //导出
    const listExport = async () => {
        let result = await breakingService.export({...searchParam,
            canteenId:searchParam.canteenId ?? restaurantSourceOne?.id
        });
        convertRes2Blob(result)
    }

    //列表数据源
    const [dataDetailSource, setDataDetailSource] = useState<IBreakingDetailDetial[]>([]);
    const [pgDetailCfg, setPgDetailCfg] = useState<TablePaginationConfig>(initPgCfg);

    const loadDataDetail = async (page?: ISearchPage, searchValue: IBreakingDetailFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            userId: selectedRowKeys[0],
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await breakingService.detailpage(searchParams);
        let { data, ...pg } = retData;
        setPgDetailCfg(transPgToPagination(pg));
        setDataDetailSource(data)
    }
    //导出
    const detailexport = async () => {
        let result = await breakingService.detailexport({ userId: selectedRowKeys[0], });
        convertRes2Blob(result)
    }
    return {
        dataSource,
        searchParam,
        initPgCfg,
        pgCfg,
        selectedRowKeys,
        visibleModel,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setVisibleModel,
        listExport,
        loadDataDetail,
        dataDetailSource, setDataDetailSource,
        pgDetailCfg, setPgDetailCfg,
        detailexport
    }
})