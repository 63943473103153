import React, {useEffect} from "react";
import {YHModal} from "@/components/YHModal";
import {YhBox} from "@/components/YhBox";
import {useBreakingModel} from "../breakingModel";
import {Button, Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {IBreakingDetailDetial} from "../breakingType";
import {momentFormat} from "@/utils/utils";

enum IMealStatus {
    FINISHED = 'finished',     // 已完成
    CANCELED = 'canceled',     // 已取消
    UNFINISHED = 'unfinished', // 未完成
    OVERDUE = 'overdue',       // 已失约
    EVALUATED = 'evaluated',   // 已评价
}

const IMealStatusType: any = {
    finished: "已完成",
    canceled: "已取消",
    unfinished: "未完成",
    overdue: "已失约",
    evaluated: "已评价",
}

export const BreakingDetailModel = () => {
    const {
        loadData,
        visibleModel: visible, setVisibleModel,
        loadDataDetail,
        dataDetailSource,
        pgDetailCfg,
        detailexport
    } = useBreakingModel();

    const onOK = () => {
        onCancel()
    }
    const onCancel = () => {
        setVisibleModel(false)
    }
    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => detailexport().then()}
        >导出</Button>
    </>
    useEffect(() => {
        visible && loadDataDetail()
    }, [visible])
    const columns: ColumnsType<IBreakingDetailDetial> = [
        {
            title: '序号',
            width: 50,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '人员名称',
            width: 120,
            dataIndex: 'userName',
        }, {
            title: '所属部门',
            width: 120,
            dataIndex: 'orgName',
        }, {
            title: '报餐地点',
            width: 130,
            dataIndex: 'canteenName',
        }
        , {
            title: '用餐状态',
            width: 130,
            dataIndex: 'mealStatus',
            render: (text: any) => IMealStatusType[text]
        }, {
            title: '报餐时间',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '用餐时间',
            width: 120,
            dataIndex: 'mealTime',
            render: (text) => momentFormat(text)
        }, {
            title: '取消时间',
            width: 120,
            dataIndex: 'cancelTime',
            render: (text) => momentFormat(text)
        }
    ];
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        onOk={onOK}
        title="人员统计"
        width={1100}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title={title}>
            <Table
                rowKey={record => record.id as number}
                columns={columns}
                dataSource={dataDetailSource}
                pagination={{
                    ...pgDetailCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgDetailCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadDataDetail({pageNum, pageSize})
                    }
                }}
                scroll={{x: 'max-content', y: 520}}
            >
            </Table>
        </YhBox>

    </YHModal>
}
